var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"info-servicios mx-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v("Informe de servicios")]),_c('v-card-subtitle',[_vm._v(" Desde este apartado encontrarás la cantidad de servicios facturados en el rango de fecha seleccionado. ")]),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":20,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","rounded":"","dense":"","label":"Fecha inicial","append-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu_1),callback:function ($$v) {_vm.menu_1=$$v},expression:"menu_1"}},[_c('v-date-picker',{attrs:{"scrollable":"","locale":"es-co","color":"primary","first-day-of-week":"1"},on:{"input":function($event){_vm.menu_1 = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":20,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","rounded":"","dense":"","label":"Fecha final","append-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date_fin),callback:function ($$v) {_vm.date_fin=$$v},expression:"date_fin"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu_2),callback:function ($$v) {_vm.menu_2=$$v},expression:"menu_2"}},[_c('v-date-picker',{attrs:{"scrollable":"","locale":"es-co","color":"primary","first-day-of-week":"1"},on:{"input":function($event){_vm.menu_2 = false}},model:{value:(_vm.date_fin),callback:function ($$v) {_vm.date_fin=$$v},expression:"date_fin"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.servicios,"dense":"","footer-props":{ itemsPerPageText: 'Servicios' }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"px-0",attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"outlined":"","rounded":"","dense":"","append-icon":"mdi-magnify","label":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"color":"black","dark":""},on:{"click":function($event){return _vm.download()}}},[_c('v-icon',[_vm._v("mdi-download")]),_vm._v(" Descargar ")],1)],1)],1)],1)]},proxy:true},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.total))+" ")]}},{key:"item.detalle",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"my-1",attrs:{"fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.openDetalle(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver detalle")])])]}},{key:"body.append",fn:function(){return [_c('tr',[_c('th',[_vm._v(" Totales ")]),_c('th',[_vm._v(" "+_vm._s(_vm.totalizacion.cantidad)+" ")]),_c('th',{attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.totalizacion.total))+" ")])])]},proxy:true}])}),_c('v-dialog',{attrs:{"scrollable":"","max-width":"800px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.detalle)?_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.detalle.servicio)+" ")]),_c('v-card-text',[_c('v-data-table',{staticClass:"mt-2",attrs:{"search":_vm.search_detalle,"dense":"","headers":_vm.SubHeader,"items":_vm.detalle.data,"footer-props":{ itemsPerPageText: 'Facturas' }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"px-0",attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"outlined":"","rounded":"","dense":"","append-icon":"mdi-magnify","label":"Buscar"},model:{value:(_vm.search_detalle),callback:function ($$v) {_vm.search_detalle=$$v},expression:"search_detalle"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"color":"black","dark":""},on:{"click":function($event){return _vm.downloadDetalle()}}},[_c('v-icon',[_vm._v("mdi-download")]),_vm._v(" Descargar ")],1)],1)],1)],1)]},proxy:true},{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.fecha).format("ll"))+" ")]}},{key:"item.factura",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":'/detallefactura/' + item.id_factura}},[_c('span',[_vm._v(" "+_vm._s(_vm._f("folio")(item.factura))+" ")])])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(parseFloat(item.total)))+" ")]}},{key:"body.append",fn:function(){return [_c('tr',[_c('th',{attrs:{"colspan":"4"}},[_vm._v(" Totales ")]),_c('th',[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.totalizacionDetalle))+" ")])])]},proxy:true}],null,false,2300234982)})],1)],1):_vm._e()],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }