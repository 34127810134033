<template>
  <div class="info-servicios mx-2">
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <v-card>
          <v-card-title>Informe de servicios</v-card-title>
          <v-card-subtitle>
            Desde este apartado encontrarás la cantidad de servicios facturados
            en el rango de fecha seleccionado.
          </v-card-subtitle>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="4" sm="6">
                <v-menu
                  v-model="menu_1"
                  :close-on-content-click="false"
                  :nudge-right="20"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      outlined
                      rounded
                      dense
                      label="Fecha inicial"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    scrollable
                    v-model="date"
                    locale="es-co"
                    color="primary"
                    first-day-of-week="1"
                    @input="menu_1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="4" sm="6">
                <v-menu
                  v-model="menu_2"
                  :close-on-content-click="false"
                  :nudge-right="20"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date_fin"
                      outlined
                      rounded
                      dense
                      label="Fecha final"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    scrollable
                    v-model="date_fin"
                    locale="es-co"
                    color="primary"
                    first-day-of-week="1"
                    @input="menu_2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-data-table
                  :headers="headers"
                  :search="search"
                  :items="servicios"
                  dense
                  :footer-props="{ itemsPerPageText: 'Servicios' }"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-row dense>
                        <v-col cols="4" class="px-0">
                          <v-text-field
                            outlined
                            rounded
                            dense
                            append-icon="mdi-magnify"
                            label="Buscar"
                            v-model="search"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-btn color="black" dark @click="download()">
                            <v-icon>mdi-download</v-icon>
                            Descargar
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.total="{ item }">
                    {{ item.total | currency }}
                  </template>
                  <template v-slot:item.detalle="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          fab
                          small
                          color="primary"
                          v-on="on"
                          v-bind="attrs"
                          @click="openDetalle(item)"
                          class="my-1"
                        >
                          <v-icon>mdi-eye</v-icon>
                        </v-btn>
                      </template>
                      <span>Ver detalle</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:body.append>
                    <tr>
                      <th>
                        Totales
                      </th>
                      <th>
                        {{ totalizacion.cantidad }}
                      </th>
                      <th colspan="2">
                        {{ totalizacion.total | currency }}
                      </th>
                    </tr>
                  </template>
                </v-data-table>
                <v-dialog v-model="dialog" scrollable max-width="800px">
                  <v-card v-if="detalle">
                    <v-card-title>
                      {{ detalle.servicio }}
                    </v-card-title>
                    <v-card-text>
                      <v-data-table
                        :search="search_detalle"
                        dense
                        :headers="SubHeader"
                        :items="detalle.data"
                        class="mt-2"
                        :footer-props="{ itemsPerPageText: 'Facturas' }"
                      >
                        <template v-slot:top>
                          <v-toolbar flat>
                            <v-row dense>
                              <v-col cols="4" class="px-0">
                                <v-text-field
                                  outlined
                                  rounded
                                  dense
                                  append-icon="mdi-magnify"
                                  label="Buscar"
                                  v-model="search_detalle"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="4">
                                <v-btn
                                  @click="downloadDetalle()"
                                  color="black"
                                  dark
                                >
                                  <v-icon>mdi-download</v-icon>
                                  Descargar
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-toolbar>
                        </template>
                        <template v-slot:item.fecha="{ item }">
                          {{ moment(item.fecha).format("ll") }}
                        </template>
                        <template v-slot:item.factura="{ item }">
                          <router-link
                            :to="'/detallefactura/' + item.id_factura"
                          >
                            <span>
                              {{ item.factura | folio }}
                            </span>
                          </router-link>
                        </template>
                        <template v-slot:item.total="{ item }">
                          {{ parseFloat(item.total) | currency }}
                        </template>
                        <template v-slot:body.append>
                          <tr>
                            <th colspan="4">
                              Totales
                            </th>
                            <th>
                              {{ totalizacionDetalle | currency }}
                            </th>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
import XLSX from "xlsx";
export default {
  data: () => ({
    menu_1: false,
    menu_2: false,
    date: moment().format("YYYY-MM-DD"),
    date_fin: moment().format("YYYY-MM-DD"),
    search: "",
    search_detalle: "",
    dialog: false,
    headers: [
      {
        text: "Servicio",
        value: "servicio",
        align: "start",
        sortable: false,
      },
      {
        text: "Cantidad",
        value: "cantidad",
        filterable: false,
      },
      {
        text: "Total",
        value: "total",
        filterable: false,
      },
      {
        text: "Detalle",
        value: "detalle",
        filterable: false,
        sortable: false,
      },
    ],
    SubHeader: [
      {
        text: "Fecha",
        value: "fecha",
        align: "start",
        sortable: false,
      },
      {
        text: "Cliente",
        value: "cliente",
        sortable: false,
      },
      {
        text: "Factura",
        value: "factura",
      },
      {
        text: "Profesional",
        value: "profesional",
        sortable: false,
        filterable: false,
      },
      {
        text: "Valor",
        value: "total",
        sortable: false,
        filterable: false,
      },
    ],
    servicios: [],
    detalle: null,
  }),
  methods: {
    loadSalidasServicios() {
      const body = {
        route: "/salidas_servicios",
        params: {
          date: JSON.stringify({ inicio: this.date, fin: this.date_fin }),
        },
      };
      this.$store.dispatch("get_data", body).then((response) => {
        if (response.data.success) {
          this.servicios = response.data.data;
        }
      });
    },
    openDetalle(item) {
      this.detalle = {
        servicio: item.servicio,
        data: item.data,
      };
      this.dialog = true;
    },
    download() {
      const name = "Informe-Servicios";
      let data = this.servicios.map((servicio) => {
        return {
          Columna_A: servicio.servicio,
          Columna_B: servicio.cantidad,
          Columna_C: parseFloat(servicio.total),
        };
      });
      data.unshift(
        {
          Columna_A: "Fecha inicial",
          Columna_B: this.moment(this.date).format("ll"),
          Columna_C: "",
        },
        {
          Columna_A: "Fecha final",
          Columna_B: this.moment(this.date_fin).format("ll"),
          Columna_C: "",
        },
        {
          Columna_A: "Servicio",
          Columna_B: "Cantidad",
          Columna_C: "Total",
        }
      );
      data.push({
        Columna_A: "Totales",
        Columna_B: this.totalizacion.cantidad,
        Columna_C: this.totalizacion.total,
      });
      let excel = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      const filename = name;
      XLSX.utils.book_append_sheet(workbook, excel, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
    downloadDetalle() {
      const name = this.detalle.servicio;
      let data = this.detalle.data.map((item) => {
        return {
          Columna_A: moment(item.fecha).format("ll"),
          Columna_B: item.cliente,
          Columna_C: this.$options.filters.folio(item.factura),
          Columna_D: item.profesional,
          Columna_E: parseFloat(item.total),
        };
      });

      data.unshift({
        Columna_A: "Fecha",
        Columna_B: "Cliente",
        Columna_C: "Factura",
        Columna_D: "Profesional",
        Columna_E: "Valor",
      });

      data.push({
        Columna_A: "Total",
        Columna_B: "",
        Columna_C: "",
        Columna_D: "",
        Columna_E: this.totalizacionDetalle,
      });
      let excel = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      const filename = name;
      XLSX.utils.book_append_sheet(workbook, excel, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
    moment(input) {
      return moment(input);
    },
  },
  created() {
    this.loadSalidasServicios();
  },
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["informes"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
  watch: {
    date(val) {
      this.loadSalidasServicios();
      return this.date;
    },
    date_fin(val) {
      this.loadSalidasServicios();
      return this.date_fin;
    },
  },
  computed: {
    totalizacion() {
      return {
        cantidad: this.servicios.reduce((index, item) => {
          return index + parseFloat(item.cantidad);
        }, 0),
        total: this.servicios.reduce((index, item) => {
          return index + parseFloat(item.total);
        }, 0),
      };
    },
    totalizacionDetalle() {
      if (this.detalle)
        return this.detalle.data.reduce((index, item) => {
          return index + parseFloat(item.total);
        }, 0);
      else return [];
    },
  },
};
</script>
